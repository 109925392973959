<template>
    <tr>
        <td :class="getStageNameClass(stage)" class="pl-2">
            {{ stage.name }}
        </td>
        <td v-for="(_, index) in displayPrizesCount" :key="index" class="text-left">
            <Item v-if="item = masterStore.getItem(stage.prizes.main[index])" :item="item" :max-width="iconMaxWidth" class="ma-1" />
        </td>
    </tr>
</template>

<script setup>
import { useDisplay } from 'vuetify'
import { useMasterStore } from '@/store/master'
import Item from './Item.vue'

const displayPrizesCount = 3

const { name } = useDisplay()
const masterStore = useMasterStore()

const iconMaxWidth = name.value === 'xs' ? 40 : 76

const getStageNameClass = stage => [
    `border-${stage.season}`,
    'text-left',
    `text-${name.value === 'xs' ? 'caption' : 'body-1'}`
]

defineProps({
    stage: { type: Object, required: true },
})
</script>

<style>
.border-spring {
    border-left: 8px solid pink;
}
.border-summer {
    border-left: 8px solid lightgreen;
}
.border-autumn {
    border-left: 8px solid violet;
}
.border-winter {
    border-left: 8px solid lightblue;
}
</style>