import { defineStore } from 'pinia'
import data from '@/constants/data.json'
import { useSelectionStore } from '@/store/selection'
import { compareIndex, getItemsFromItemIds } from '@/misc/utilities'

const prizeRanks = ['main']

/**
 * 対象のステージの賞品に指定したアイテムリストの全てが含まれているか
 * @param {Stage} stage 対象のステージ
 * @param {Item[]} items アイテムリスト
 * @returns true: 含まれている, false: 含まれていない
 */
const findInPrizes = (stage, items) => {
    return prizeRanks.some(
        prizeRank => findInPrizesByPrizeRank(stage, prizeRank, items)
    )
}

/**
 * 対象のステージの賞品に指定したアイテムリストの全てが含まれているか
 * @param {Stage} stage 対象のステージ
 * @param {('main'|'sub')} prizeRank 賞品ランク
 * @param {Item[]} items アイテムリスト
 * @returns true: 含まれている, false: 含まれていない
 */
const findInPrizesByPrizeRank = (stage, prizeRank, items) => {
    return items.every(
        item => stage.prizes[prizeRank].includes(item.id)
    )
}

const embedIndex = (obj, index) => ({ ...obj, index })
const versionNumber = data.versionNumber
const items = data.items.map(embedIndex)
const typeGroups = data.typeGroups.map(embedIndex)
const stages = data.stages.map(embedIndex)

/**
 * マスタデータストアを提供する
 */
export const useMasterStore = defineStore('master', {
    state: () => {
        const arrangedItems = items.map(item => {
            const typeGroup = typeGroups.find(tg => tg.types.map(t => t.id).includes(item.type))
            return {
                ...item,
                typeGroupId: typeGroup ? typeGroup.id : undefined,
            }
        })
        const displayTypeGroupIds = ["book", "piece", "charm"]
        const displayTypeGroups = typeGroups.filter(tg => displayTypeGroupIds.includes(tg.id))
        const displayItems = displayTypeGroups.map(typeGroup => ({
            ...typeGroup,
            types: typeGroup.types.map(type => ({
                ...type,
                items: getItemsFromItemIds(type.itemIds, arrangedItems),
            }))
        }))
        return {
            versionNumber,
            items: arrangedItems,
            displayItems,
            typeGroups,
            stages,
        }
    },
    getters: {
        /**
         * 表示用のアイテムリストを取得する
         * @returns 表示用のアイテムリスト
         */
        selectableItems: state => {
            const selectionStore = useSelectionStore()
            return state.displayItems.map(typeGroup => ({
                ...typeGroup,
                types: typeGroup.types.map(type => ({
                    ...type,
                    items: type.items.map(item => ({
                        ...item,
                        selected: selectionStore.isSelected(item)
                    })),
                })),
            }))
        },
        getSelectedItemInTypeGroup: state => {
            return typeGroupId => {
                const typeGroup = state.displayItems.find(tg => tg.id === typeGroupId)
                if (!typeGroup) {
                    return undefined
                }

                const selectionStore = useSelectionStore()
                const typeGroupItems = typeGroup.types.flatMap(is => is.items)
                return typeGroupItems.find(i => selectionStore.isSelected(i))
            }
        },
        /**
         * フィルタ済みのステージリストを取得する
         * @returns フィルタ済みのステージリスト
         */
        filteredStages: state => {
            const selectionStore = useSelectionStore()
            const selectedItems = selectionStore.items
            if (selectedItems.length === 0) {
                return state.stages;
            }
            const filtered = state.stages.filter(s => findInPrizes(s, selectedItems))
            return filtered.sort(compareIndex)
        },
        getItem: state => itemId => state.items.find(item => item.id === itemId),
    },
    // 利用者側からマスタデータの変更を要求することはないので、actionsは不要
    // actions: {}
})
