<template>
    <v-img
        :width="size"
        aspect-ratio="1"
        :src="getItemImageNameById(item.id)" class="item-icon"
        :alt="item.name"
        :class="[dark ? 'dark' : '']" />
</template>

<script setup>
/**
 * アイテムIDから画像パスを取得（public配下）
 * @param {string} itemId アイテムID
 */
const getItemImageNameById = itemId => `assets/images/icon-${itemId}.webp`

defineProps({
    item: { type: Object, required: true },
    dark: { type: Boolean, required: false, default: false },
    size: { type: Number, required: false, default: 118 },
})
</script>

<style>
.dark {
    filter: brightness(.5);
}
</style>
