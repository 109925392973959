import { defineStore } from 'pinia'
import { useSelectionStore } from '@/store/selection'
import { usePersistenceStore } from '@/store/persistence'

export const modalName = 'saveConditionModal'

/**
 * 条件保存確認モーダルダイアログストアを提供する
 */
export const useSaveCondition = defineStore('saveCondition', {
    state: () => ({
        isShown: false,
        description: '',
    }),
    actions: {
        /**
         * モーダル表示準備
         */
        prepare() {
            this.description = ''
        },
        /**
         * モーダルを表示する
         */
        showModal() {
            this.isShown = true
            this.description = ''
        },
        /**
         * モーダルを非表示にする
         */
        hideModal() {
            this.isShown = false
        },
        /**
         * 条件を保存する
         */
        save() {
            const selectionStore = useSelectionStore()
            const persistenceStore = usePersistenceStore()

            persistenceStore.saveCondition(selectionStore.items, this.description)

            this.hideModal()
        },
    }
})
