export const theme = {
    dark: false,
    colors: {
        // TODO 色を決める
        primary: "#BE1400",
        secondary: "#FCE8E6",
        accent: "#383834",
        error: "#ffeb3b",
        warning: "#ffc107",
        info: "#ff5722",
        success: "#795548",
        background: "#E8E8E3",
        surface: '#F7F7F3',
    },
}