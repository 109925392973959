import { createApp } from 'vue'
import './registerServiceWorker'

// setup pinia plugin
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

// setup vuetify plugin
import 'vuetify/styles'
import { createVuetify } from'vuetify'
import * as components from 'vuetify/components'
import { VSnackbarQueue } from 'vuetify/lib/labs/components.mjs'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import { theme } from './constants/themes'
const vuetify = createVuetify({
    components: {
        ...components,
        VSnackbarQueue,
    },
    directives,
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        defaultTheme: "defaultTheme",
        themes: {
            defaultTheme: theme,
        },
    },
})

import App from './App.vue'
createApp(App)
    .use(pinia)
    .use(vuetify)
    .mount('#app')
