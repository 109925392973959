<template>
  <v-app>
    <v-app-bar
      color="primary">
      <v-app-bar-nav-icon
        :icon="drawer ? '$close' : '$menu'"
        @click.stop="toggleDrawer" />
      <v-toolbar-title>
        {{ navTitle }}
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
      class="nav"
      temporary
      v-model="drawer">
      <Navigation />
    </v-navigation-drawer>
    <v-main class="main">
      <HelloWorld />
    </v-main>
    <v-footer class="d-flex align-end justify-end text-caption">
      Data version: {{ masterStore.versionNumber }}
    </v-footer>
    <Snackbar />
  </v-app>
</template>

<script setup>
import { ref } from 'vue'
import { useDisplay } from 'vuetify'
import Navigation from '@/components/Navigation.vue'
import HelloWorld from '@/components/HelloWorld.vue'
import Snackbar from '@/components/Snackbar.vue'
import { useMasterStore } from './store/master'

const { name } = useDisplay()
const masterStore = useMasterStore()

const drawer = ref(false)

const toggleDrawer = () => {
  drawer.value = !drawer.value
}

const navTitle = name.value === 'xs' ? 'リンクラ報酬検索' : 'Link！Like！ラブライブ！ クエストライブ報酬検索ツール'
</script>

<style>
#app {
  font-family: "noto-sans-cjk-jp", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #383834;
}
.v-application {
  [class*='text-'] {
    font-family: "noto-sans-cjk-jp", sans-serif !important;
  }
  font-family: "noto-sans-cjk-jp", sans-serif !important;
}
.nav {
  text-align: center;
}
.main {
  text-align: center;
}
</style>
