<template>
  <v-btn @click="showModal" :disabled="!selectionStore.hasSelection" class="mx-2" color="primary">
      条件を保存
      <v-dialog v-model="saveConditionStore.isShown" transition="slide-x-transition" max-width="500" class="align-start">
          <v-card title="条件を保存">
              <v-card-text>
                  <v-text-field
                    ref="description"
                    label="メモ（任意、カード名など）"
                    v-model="saveConditionStore.description"></v-text-field>
              </v-card-text>
              <v-card-actions>
                  <v-btn @click="saveConditionStore.save" class="mx-2" color="primary" variant="elevated">保存</v-btn>
                  <v-btn @click="saveConditionStore.hideModal" class="mx-2" color="surface" variant="elevated">キャンセル</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </v-btn>
</template>

<script setup>
import { ref } from 'vue'
import { useSelectionStore } from '@/store/selection'
import { useSaveCondition } from '@/store/saveCondition'

const description = ref('description')
const selectionStore = useSelectionStore()
const saveConditionStore = useSaveCondition()

const showModal = () => {
  saveConditionStore.showModal()

  // 少し待ってから、メモ入力にオートフォーカス
  setTimeout(() => {
    description.value.focus()
  }, 200)
}
</script>
