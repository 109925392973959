import { defineStore } from 'pinia'
import { usePersistenceStore } from './persistence'

/**
 * 選択中データストアを提供する
 */
export const useSelectionStore = defineStore('selection', {
    state: () => {
        const persistenceStore = usePersistenceStore()
        const items = persistenceStore.loadCurrentCondition() || []

        return {
            items,
        }
    },
    getters: {
        /**
         * 指定したアイテムが選択されているか
         * @param {Item} item 対象のアイテム
         * @returns true: 選択されている, false: 選択されていない
         */
        isSelected: state => item => state.items.find(i => i.id === item.id),
        hasSelection: state => !!state.items.length
    },
    actions: {
        /**
         * 指定したアイテムを選択状態にする
         * @param {Item} item 対象のアイテム
         */
        selectItem(item) {
            const persistenceStore = usePersistenceStore()
            this.items = this.items.filter(i => i.typeGroupId !== item.typeGroupId)
            this.items.push({ ...item })
            persistenceStore.saveCurrentCondition(this.items)
        },
        /**
         * 指定したアイテムを非選択状態にする
         * @param {Item} item 対象のアイテム
         */
        unselectItem(item) {
            const persistenceStore = usePersistenceStore()
            this.items = this.items.filter(i => i.id !== item.id)
            persistenceStore.saveCurrentCondition(this.items)
        },
        /**
         * 指定したアイテムの選択状態を切り替える
         * @param {Item} item 対象のアイテム
         */
        toggleSelectionItem(item) {
            if (this.isSelected(item)) {
                this.unselectItem(item)
            } else {
                this.selectItem(item)
            }
        },
        /**
         * 指定したアイテムリストのみを選択した状態にする
         * @param {Item[]} items 復元するアイテムリスト
         */
        restoreSelectionItem(items) {
            const persistenceStore = usePersistenceStore()
            this.clearSelectionItem()
            items.forEach(item => {
                this.items.push({ ...item })
            });
            persistenceStore.saveCurrentCondition(this.items)
        },
        /**
         * 全ての選択状態を解除する
         */
        clearSelectionItem() {
            const persistenceStore = usePersistenceStore()
            persistenceStore.saveCurrentCondition([])
            this.$reset()
        },
    },
})
