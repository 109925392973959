<template>
    <v-container class="ma-4">
        <p class="text-h5">欲しいアイテムを選択</p>
        <v-divider class="my-2" />
        <v-expansion-panels class="border" variant="accordion" multiple>
            <v-expansion-panel v-for="typeGroup in masterStore.selectableItems" :key="typeGroup.id" rounded="0" elevation="0">
                <v-expansion-panel-title>
                    <div class="d-flex align-center">
                        {{ typeGroup.name }}
                        <div class="d-flex align-center" v-for="item in [masterStore.getSelectedItemInTypeGroup(typeGroup.id)].filter(Boolean)" :key="item.id">
                            <Item class="ml-4" :item="item" width="20" />
                            <v-icon icon="mdi-close-thick"
                                rounded="0"
                                color="red"
                                size="small"
                                elevation="0"
                                class="ml-2"
                                @click.stop="selectionStore.unselectItem(item)" />
                        </div>
                    </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <v-expansion-panels class="border" variant="accordion">
                        <v-expansion-panel v-for="type in typeGroup.types" :key="type.id" rounded="0" elevation="0">
                            <v-expansion-panel-title>
                                {{ type.name }}
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <div class="d-flex justify-center">
                                    <Item v-for="item in type.items" :key="item.id" @click="selectionStore.toggleSelectionItem(item)"
                                        class="ma-1" :item="item" :dark="!item.selected" :max-width="iconMaxWidth()" />
                                </div>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider class="my-2" />
        <div class="d-flex justify-center">
            <SaveConditionModal />
            <v-btn @click="selectionStore.clearSelectionItem" class="mx-2" color="surface">クリア</v-btn>
        </div>
    </v-container>
</template>

<script setup>
import { useDisplay } from 'vuetify'
import Item from './Item.vue'
import SaveConditionModal from './SaveConditionModal.vue'
import { useMasterStore } from '@/store/master'
import { useSelectionStore } from '@/store/selection'

const { name } = useDisplay()
const masterStore = useMasterStore()
const selectionStore = useSelectionStore()

const iconMaxWidth = () => name.value === 'xs' ? '22%' : '100px'
</script>
