<template>
    <div>
        <p class="ma-2 text-h6">保存済みの条件</p>
        <v-btn class="ma-2" color="red" @click="persistenceStore.clearCondition">全て削除</v-btn>
    </div>
    <v-divider class="my-2" />
    <v-list class="overflow-y-auto">
        <v-list-item v-for="condition in persistenceStore.displayConditions" :key="condition.id">
            <Condition :condition="condition" />
        </v-list-item>
    </v-list>
</template>

<script setup>
import Condition from '@/components/Condition.vue'
import { usePersistenceStore } from '@/store/persistence'

const persistenceStore = usePersistenceStore()
</script>
