
/**
 * indexを持つObject同士を比較する
 * @param {*} a 比較対象A
 * @param {*} b 比較対象B
 * @returns 正値: Aの方を優先する, 0: 同じ, 負値: Bの方を優先する
 */
export const compareIndex = (a, b) => a.index - b.index

/**
 * アイテムIDリストからアイテムリストを取得する
 * @param {string[]} itemIds アイテムIDリスト
 * @param {Item[]} itemIds 元のアイテムリスト
 * @returns アイテムリスト
 */
export const getItemsFromItemIds = (itemIds, items) => {
    return itemIds
        .map(itemId => items.find(item => item.id === itemId))
        .filter(Boolean)
        .sort(compareIndex)
}
