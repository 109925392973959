<template>
    <v-sheet
        class="d-flex mx-1 pa-1 border border-accent"
        color="secondary"
        @click="selectionStore.restoreSelectionItem(persistenceStore.loadCondition(condition.id))">
        <div class="d-flex flex-fill flex-column align-start">
            <div>
                <Item v-for="item in condition.items" :key="item.id" class="d-inline-block mx-1" :item="item" width="30" />
            </div>
            <div v-if="condition.description" class="text-subtitle-2 text-left">
                {{ condition.description }}
            </div>
        </div>
        <div>
            <v-icon
                icon="mdi-close-thick"
                rounded="0"
                color="red"
                size="small"
                elevation="0"
                @click="persistenceStore.deleteCondition(condition.id)" />
        </div>
    </v-sheet>
</template>

<script setup>
import Item from './Item.vue'
import { useSelectionStore } from '@/store/selection'
import { usePersistenceStore } from '@/store/persistence'

const selectionStore = useSelectionStore()
const persistenceStore = usePersistenceStore()

defineProps({
    condition: { type: Object, required: true },
})
</script>