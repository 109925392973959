<template>
    <v-data-table
        :headers="headers"
        :items="masterStore.filteredStages"
        :items-per-page="-1"
        disable-sort
        hide-default-footer>
        <template v-slot:headers="{ columns }">
            <tr>
                <th v-for="column in columns" :key="column.key" class="font-weight-bold px-4" :width="column.width">
                    {{ column.title }}
                </th>
            </tr>
        </template>
        <template v-slot:item="{ item }">
            <ResultStage :stage="item" />
        </template>
    </v-data-table>
</template>

<script setup>
import { useMasterStore } from '@/store/master'
import ResultStage from './ResultStage.vue'

const masterStore = useMasterStore()

const headers = [
    { title: 'ステージ', key: 'stage', sortable: false },
    { title: '報酬1', key: 'prize1', sortable: false, width: '18%' },
    { title: '報酬2', key: 'prize2', sortable: false, width: '18%' },
    { title: '報酬3', key: 'prize3', sortable: false, width: '18%' },
]
</script>