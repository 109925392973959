import { defineStore } from 'pinia'
import { useMasterStore } from '@/store/master'
import { getItemsFromItemIds } from '@/misc/utilities'
import { useSnackbarStore } from '@/store/snackbar'

/**
 * 実行環境を表す文字列
 * ブラウザとPWAで状態を共有しないために使用
 */
const runningOn = window.matchMedia('(display-mode: standalone)').matches ? 'pwa' : 'browser'
console.log(window.matchMedia('(display-mode: standalone)'))

const addMessage = message => {
    setTimeout(() => {
        const snackbarStore = useSnackbarStore()
        snackbarStore.addMessage(message, 2000)
    }, 100)
}

/**
 * 永続化対象データストアを提供する
 */
export const usePersistenceStore = defineStore('persistence', {
    state: () => ({
        [runningOn]: {
            conditions: [],
            currentCondition: [],
        },
    }),
    persist: {
        key: 'link-like-item-helper',
        paths: [
            `${runningOn}.conditions`,
            `${runningOn}.currentCondition`,
        ],
    },
    getters: {
        /**
         * 表示用の条件リストを取得する
         * @returns 表示用の条件リスト
         */
        displayConditions: state => state[runningOn].conditions.map(condition => {
            const masterStore = useMasterStore()
            const masterItems = masterStore.items
            const items = getItemsFromItemIds(condition.itemIds, masterItems)

            return {
                ...condition,
                items,
            }
        })
    },
    actions: {
        /**
         * 条件を保存する（アイテムリストを保存する）
         * @param {Item[]} saveItems 保存する条件のアイテムリスト
         */
        saveCondition(saveItems, description) {
            if (!saveItems.length) {
                return
            }

            const saveItemIds = saveItems.map(i => i.id)
            const condition = {
                itemIds: saveItemIds,
                description,
                id: crypto.randomUUID(),
            }
            this[runningOn].conditions.push(condition)
            
            const message = description ? `${description} を保存しました` : '現在の条件を保存しました'
            addMessage(message)
        },
        /**
         * 条件を読み込む（アイテムリストを取得する）
         * @param {string} conditionId 条件ID
         * @returns 条件に含まれるアイテムリスト
         */
        loadCondition(conditionId) {
            const masterStore = useMasterStore()
            const items = masterStore.items
            
            const condition = this[runningOn].conditions.find(h => h.id === conditionId)
            if (!condition) {
                return []
            }

            const message = condition.description ? `${condition.description} を復元しました` : '条件を復元しました'
            addMessage(message)
            return getItemsFromItemIds(condition.itemIds, items)
        },
        /**
         * 条件を削除する
         * @param {string} conditionId 条件ID
         */
        deleteCondition(conditionId) {
            const index = this[runningOn].conditions.findIndex(i => i.id === conditionId)
            if (index < 0) {
                return
            }
            const condition = this[runningOn].conditions[index]
            const message = condition.description ? `${condition.description} を削除しました` : '条件を削除しました'
            this[runningOn].conditions.splice(index, 1)
            addMessage(message)
        },
        /**
         * 全ての条件を削除する
         */
        clearCondition() {
            this[runningOn].conditions = []
            addMessage('全ての条件を削除しました')
        },
        saveCurrentCondition(items) {
            const itemIds = items.map(i => i.id)
            this[runningOn].currentCondition = [ ...itemIds ]
        },
        loadCurrentCondition() {
            if (!this[runningOn].currentCondition) {
                return []
            }

            const masterStore = useMasterStore()
            const items = masterStore.items

            return getItemsFromItemIds(this[runningOn].currentCondition, items)
        },
    }
})
