import { defineStore } from 'pinia'

export const useSnackbarStore = defineStore('snackbar', {
    state: () => ({
        messages: [],
    }),
    actions: {
        addMessage(message, timeout) {
            this.messages.push({
                text: message,
                timeout,
            })
        },
    },
})